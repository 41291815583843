#snackbar {
  visibility: hidden;
  width: calc(100% - 30px);
  background-color: #21b2da;
  color: #fff;
  text-align: center;
  padding: 15px;
  position: fixed;
  bottom: 0px;
  z-index: 200;
}
@media screen and (min-width: 1024px) {
  #snackbar {
    bottom: 0px;
    padding: 15px 15px 45px;
  }
}

#snackbar.show {
  visibility: visible;
  animation-name: fadein;
  animation-duration: 5s;
}

@keyframes fadein {
  0% {
    bottom: 0;
    opacity: 0;
  }
  10% {
    bottom: 65px;
    opacity: 1;
  }
  90% {
    bottom: 65px;
    opacity: 1;
  }
  100% {
    bottom: 0;
    opacity: 0;
  }
}

@media screen and (min-width: 1024px) {
  @keyframes fadein {
    0% {
      bottom: -90px;
      opacity: 0;
    }
    10% {
      bottom: 0px;
      opacity: 1;
    }
    90% {
      bottom: 0px;
      opacity: 1;
    }
    100% {
      bottom: -90px;
      opacity: 0;
    }
  }
}
